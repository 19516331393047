<template>
   <div class="container main-body">
      <div class="row faq">

         <div class="col-sm-12">
            <b-alert variant="danger" show dismissible>
               Customer service will never ask for confidential information or request a copy of your QR codes
            </b-alert>
         </div>

         <div class="col-sm-10">
            <h4>Request Credit</h4>
         </div>

         <p class="col-sm-12">If you are unable to create or validate your QR code set, please complete this form.</p>

         <div class="col-sm-12 col-lg-6 mt-4 form-group">
            <form>
               <label value="" for="selectDropDown" v-if="!isHidingSelectCredit">Select Credit</label>
               <select class="form-control" id="selectDropDown" v-model="selectedCredit" v-if="!isHidingSelectCredit">
                  <option value="">(Select One)</option>
                  <option v-for="creditUsed in creditsUsed" v-bind:key="creditUsed.id" v-bind:value="creditUsed">
                     {{ `${creditUsed.max_slice_count}-Slice Credit used on ${(new Date(creditUsed.activated_date)).toLocaleDateString('en-US')}` }}
                  </option>
                  <option value="unlisted">(Credit is Not Listed)</option>
                  <option value="escalate">(Escalate to Supervisor)</option>
               </select>
               <div v-if="selectedCredit">
                  <div v-if="!isHidingSelectCredit">
                     <p style="margin-top: 15px" v-if="selectedCredit=='unlisted'">
                        Don't see your credit in the list? Please fill out this form and explain your issue, and our support team will be happy to see how we can assist.
                     </p>
                     <p style="margin-top: 15px" v-if="selectedCredit == 'escalate'">
                        Need to contact a supervisor? Please fill out this form and explain your issue, and we will be happy to see how we can assist.
                     </p>
                  </div>

                  <label for="nameEntry">Name (optional)</label>
                  <input type="name" class="form-control form-control-lg" id="nameEntry" aria-describedby="" placeholder="name" v-model="name" />

                  <label for="nameEntry">Device <span class="text-danger font-weight-bold">*</span></label>
                  <select class="form-control" id="selectDeviceDropDown" v-model="selectedDevice">
                     <option value="">(Select One)</option>
                     <option value="desktop">Desktop</option>
                     <option value="tablet">Tablet</option>
                     <option value="mobile">Mobile</option>
                  </select>
                  <div v-if="selectedDevice=='desktop'">
                     <label for="nameEntry">OS <span class="text-danger font-weight-bold">*</span></label>
                     <select class="form-control" id="selectDeviceDropDown" v-model="desktopOs">
                        <option value="">(Select One)</option>
                        <option value="pc">PC</option>
                        <option value="mac">Mac</option>
                        <option value="other">Other</option>
                     </select>
                  </div>
                  <div class="form-group" v-if="selectedDevice!='' && (selectedDevice=='tablet' || selectedDevice=='mobile')">
                     <label for="nameEntry">OS <span class="text-danger font-weight-bold">*</span></label>
                     <select class="form-control" id="selectDeviceDropDown" v-model="mobileOs">
                        <option value="">(Select One)</option>
                        <option value="android">Android</option>
                        <option value="ios">iOS</option>
                        <option value="other">Other</option>
                     </select>
                  </div>

                  <label for="selectDeviceDropDown">Browser <span class="text-danger font-weight-bold">*</span></label>
                  <select class="form-control" id="selectDeviceDropDown" v-model="browser">
                     <option value="">(Select One)</option>
                     <option value="Chrome">Chrome</option>
                     <option value="Safari">Safari</option>
                     <option value="Edge">Edge</option>
                     <option value="Brave">Brave</option>
                     <option value="Firefox">Firefox</option>
                     <option value="Opera">Opera</option>
                     <option value="Other">Other</option>
                  </select>

                  <label for="notesInput">Describe the issue <span class="text-danger font-weight-bold">*</span></label>
                  <textarea v-model="notes" id="notesInput" class="form-control" />
               </div>

               <div class="mt-4">
                  <button
                     type="submit"
                     class="btn btn-orange btn-lg btn-block credit-submit"
                     @click="$event.preventDefault(); submitSupportRequest();"
                     v-if="((selectedDevice == 'desktop' && desktopOs !== '') || ((selectedDevice == 'mobile' || selectedDevice == 'tablet') && mobileOs !== '')) && notes !== '' && browser !== ''"
                  >
                     Submit
                  </button>
                  <button
                     type="submit"
                     class="btn btn-orange-disabled btn-lg btn-block credit-submit"
                     @click="$event.preventDefault();"
                     v-else
                  >
                     Submit
                  </button>
               </div>
            </form>
         </div>
      </div><!--END CONTAINER-->

   </div>
</template>

<script>

export default {
   name: "Validate",
   data() {
      return {
         name: "",
         notes: "",
         selectedCredit: "",
         mobileOs: "",
         desktopOs: "",
         selectedDevice: "",
         creditsRemaining: [],
         creditsUsed: [],
         browser: "",
         isHidingSelectCredit: false,
         isEscalate: false
      };
   },
   mounted() {
      window.gtag("event", "RestoreCreditPage");
      if (this.$route.query.sv) {
         this.selectedCredit = "escalate";
         this.isEscalate = true;
         this.isHidingSelectCredit = true;
      }
      this.axios
         .get("/api/account-data", { withCredentials: true })
         .then((response) => {
            console.log(response.data);
            this.creditsRemaining = response.data.credits_remaining;
            this.creditsUsed = response.data.credits_used.filter(
               function(creditUsed) { 
                  const SUBSCRIPTION = 1;
                  return !creditUsed.is_finalized && 
                     creditUsed.origin !== SUBSCRIPTION &&
                     creditUsed.retries_so_far <= 2 &&
                     creditUsed.retries_available <= creditUsed.retries_so_far &&
                     (new Date(creditUsed.activated_date)) > (Date.now() - (3600 * 1000 * 24)) && 
                     !creditUsed.is_pending_support;

               }
            );
         })
         .catch((error) => console.error(error));
   },
   methods: {
      submitSupportRequest() {
         console.log(this.selectedCredit);
         let postData = {
            creditId: this.selectedCredit.id ? this.selectedCredit.id : this.selectedCredit,
            name: this.name,
            notes: this.notes,
            mobilOs: this.mobilOs,
            desktopOs: this.desktopOs,
            device: this.selectedDevice,
            browser: this.browser
         };
         this.axios
            .post("/api/support-restore-credit", postData, { withCredentials: true })
            .then(() => {
               window.location.assign("/messages");
            })
            .catch((error) => console.error(error));
      }
   }
}
</script>
